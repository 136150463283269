@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Inter;
  }
}

.glider-slide {
  min-width: 110px !important;
}

img {
  border-radius: 8px;
}

.source-img,
.caption-img,
.wp-caption-text {
  font-size: 12px !important;
}

figcaption {
  font-size: 12px !important;
  margin-bottom: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
